import { LocationProvider, Route, Router, hydrate, prerender as ssr } from 'preact-iso'
import { Home } from './pages/Home/Home'
import { NotFound } from './pages/_404'
import './style.css'
import type { User } from '@laka/model'
import type { FunctionComponent } from 'preact'
import { Auth } from './pages/Auth/Auth'
import { AuthCallback } from './pages/AuthCallback/AuthCallback'
import { ProvideAuth } from './services/auth'
import { setCookie, trpc } from './services/trpc'

const LocationProviderProper: FunctionComponent<{ url?: string }> = LocationProvider

export function App(props: { url?: string; user?: User }) {
  return (
    <LocationProviderProper url={props.url}>
      <ProvideAuth user={props.user}>
        <main>
          <Router>
            <Auth path="/auth" />
            <AuthCallback path="/auth/facebook" platform="facebook" />
            <AuthCallback path="/auth/google" platform="google" />
            <Route path="/" component={Home} />
            <Route default component={NotFound} />
          </Router>
        </main>
      </ProvideAuth>
    </LocationProviderProper>
  )
}

main()
async function main() {
  if (typeof window !== 'undefined') {
    const isAuthCallback = location.pathname.match(/^\/auth\/.+/)
    const user = isAuthCallback ? undefined : await trpc.me.query().catch(() => undefined)
    hydrate(<App user={user} />, document.getElementById('app'))
  }
}

export async function prerender(data: { url: string; cookie: string }) {
  setCookie(data.cookie)
  const user = (await trpc.me.query().catch(() => {})) as User | undefined
  return await ssr(<App url={data.url} user={user} />)
}
