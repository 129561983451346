import { createTRPCClient, httpBatchLink } from '@trpc/client'
import type { TRPCRouter } from '../../../server/src/server/trpc'
import { isBrowser } from './isBrowser'

let cookie: string | undefined

export function setCookie(value: string) {
  cookie = value
}

export const trpc = createTRPCClient<TRPCRouter>({
  links: [
    httpBatchLink({
      url: isBrowser ? '/api/trpc' : Bun.env.TRPC_URL,
      headers: isBrowser ? undefined : () => ({ cookie }),
    }),
  ],
})
