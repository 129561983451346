import { useEffect } from 'preact/hooks'

import './AuthCallback.css'
import { useLocation } from 'preact-iso'
import { useAuth } from '../../services/auth'

export function AuthCallback(params: { platform: 'facebook' | 'google' }) {
  const { oauthCallback } = useAuth()
  const { route } = useLocation()

  useEffect(() => {
    oauthCallback(params.platform).then(r => {
      if (r) {
        route('/')
      }
    })
  }, [params.platform, oauthCallback, route])

  return (
    <div class="auth-callback-page">
      <h1>Authenticating {params.platform}. Beep. Boop.</h1>
    </div>
  )
}
