import type { User } from '@laka/model'
import { createContext } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { isBrowser } from './isBrowser'
import { trpc } from './trpc'

type OAuthPlatform = 'facebook' | 'google'

const authContext = createContext({
  user: undefined as User | undefined,
  oauthLogin: undefined as (_platform: OAuthPlatform) => void,
  oauthCallback: undefined as (_platform: OAuthPlatform) => Promise<boolean>,
})

export function ProvideAuth({ children, user }) {
  const auth = useProvideAuth(user)
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export function useAuth() {
  return useContext(authContext)
}

function useProvideAuth(initialUser?: User) {
  const [user, setUser] = useState<User>(initialUser)

  useEffect(() => {
    if (!isBrowser) return
    // revalidate()
    listenForAuthResults()
  }, [])

  return { user, oauthLogin, oauthCallback }

  function revalidate() {
    return trpc.me.query().then(user => {
      setUser(() => user as User) // TODO: why response is Partial<User>?
      return !!user
    })
  }

  function listenForAuthResults() {
    window.addEventListener(
      'message',
      event => {
        if (event.origin !== location.origin) return
        if (event.data.type !== 'auth') return
        if (event.data.res.ok) revalidate()
      },
      false,
    )
  }

  function oauthLogin(platform: OAuthPlatform) {
    location.href = `/api/auth/${platform}${location.search}`
  }

  function oauthCallback(platform: OAuthPlatform) {
    if (!isBrowser) return
    const url = new URL(location.href)
    return fetch(`/api/auth/${platform}${url.search}`, { credentials: 'same-origin' })
      .then(r => r.json())
      .then(res => {
        if (res.query?.redirect_url) {
          location.href = `${res.query.redirect_url}?token=${res.token}`
        } else if (opener) {
          opener.postMessage({ type: 'auth', platform, res }, '*')
          close()
        } else {
          return revalidate()
        }
      })
  }
}
