import { useLocation } from 'preact-iso'
import x from '../../../../browser-extension/dist/lakablaka-addons.json'
import { useAuth } from '../../services/auth'

import './Home.css'

export function Home() {
  const { user, oauthLogin } = useAuth()
  const { route } = useLocation()
  return (
    <div class="home-page">
      {user ? (
        <>
          <header>Hi {user.profile.name}</header>
          <table class="home-auth">
            <tr>
              <td>Google</td>
              <td>
                {user.google ? (
                  `Connected until ${new Date(user.google.expires_at).toUTCString()}`
                ) : (
                  <button type="button" onClick={() => oauthLogin('google')}>
                    Connect
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>
                {user.facebook ? (
                  `Connected until ${new Date(user.facebook.expires_at).toUTCString()}`
                ) : (
                  <button type="button" onClick={() => oauthLogin('facebook')}>
                    Connect
                  </button>
                )}
              </td>
            </tr>
          </table>
          <table class="home-addons">
            <thead>
              <tr>
                <th>Browser</th>
                <th>Download</th>
                <th>MD5 Checksum</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Chrome</td>
                <td>
                  <a href={`/addon/${x.chrome.file}`} target="blank" download>
                    {x.chrome.file}
                  </a>
                </td>
                <td>{x.chrome.md5}</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>
                  <a href={`/addon/${x.firefox.file}`} target="blank" download>
                    {x.firefox.file}
                  </a>
                </td>
                <td>{x.firefox.md5}</td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <button type="button" onClick={() => route('/auth')}>
          Hello
        </button>
      )}
    </div>
  )
}
