import { useAuth } from '../../services/auth'
import './Auth.css'

export function Auth() {
  const { oauthLogin } = useAuth()

  return (
    <div class="auth-page">
      <div class="message">
        <div class="bubble">
          <span class="text">C'MON!</span>
        </div>
        <div class="bubble">
          <span class="text">
            CLICK
            <br />
            ALREADY!
          </span>
        </div>
      </div>
      <img src="/favicon.svg" alt="Lakablaka" />
      <button type="button" onClick={() => oauthLogin('facebook')}>
        Facebook
      </button>
      <button type="button" onClick={() => oauthLogin('google')}>
        Google
      </button>
    </div>
  )
}
